export const anchorDataTableConf = [
	{
		label: '排名',
		prop: '1'
	},
	{
		label: '主播',
		prop: '2'
	},
	{
		label: '粉丝数',
		prop: '1-3'
	},
	{
		label: '品类',
		prop: '4'
	},
	{
		label: '销量',
		prop: '5'
	},
	{
		label: '销售额',
		prop: '5'
	},
	{
		label: '操作',
		prop: '16'
	}
]

export const goodsDataTableConf = [
	{
		label: '商品信息',
		prop: '1'
	},
	{
		label: '商品来源',
		prop: '2'
	},
	{
		label: '合作主播',
		prop: '1-3'
	},
	{
		label: '直播次数',
		prop: '4'
	},
	{
		label: '销量',
		prop: '5'
	},
	{
		label: '销售额',
		prop: '5'
	},
	{
		label: '操作',
		prop: '16'
	}
]


export const monitoringTableConf = [
	{
		label: '主播信息',
		prop: '1'
	},
	{
		label: '直播平台',
		prop: '2'
	},
	{
		label: '粉丝总量',
		prop: '1-3'
	},
	{
		label: '最新活动日期',
		prop: '4'
	},
	{
		label: '销量',
		prop: '5'
	},
	{
		label: '销售额',
		prop: '5'
	},
	{
		label: '操作',
		prop: '16'
	}
]


export const dataRecordTableConf = [
	{
		label: '报名时间',
		prop: '1',
		search: true,
		type: 'daterange'
	},
	{
		label: '分类',
		prop: '2',
		search: true,
		type: 'select',
		options: []
	},
	{
		label: '商品名称',
		prop: '1-3'
	},
	{
		label: '状态',
		prop: '4',
		search: true,
		type: 'select',
		options: []
	},
	{
		label: '对接人',
		prop: '5',
		search: true,
		type: 'select',
		options: []
	},
	{
		label: '小组',
		prop: '51'
	},
	{
		label: '成员姓名',
		prop: '6',
		search: true,
		type: 'select',
		options: []
	},
	{
		label: '销量',
		prop: '54'
	},
	{
		label: '销售额',
		prop: '55'
	},
	{
		label: '操作',
		prop: '16'
	}
]


export const foundationTableConf = [
	{
		label: '商品信息',
		prop: '1'
	},
	{
		label: '商品来源',
		prop: '2'
	},
	{
		label: '直播价',
		prop: '3'
	},
	{
		label: '销量',
		prop: '4'
	},
	{
		label: '销售额',
		prop: '5'
	},
	{
		label: '直播次数',
		prop: '6'
	},
]

export const detailTableConf = [
	{
		label: '商品信息',
		prop: '1'
	},
	{
		label: '优化信息',
		prop: '2'
	},
	{
		label: '价格',
		prop: '3'
	},
	{
		label: '销量',
		prop: '4'
	},
	{
		label: '销售额',
		prop: '5'
	},
	{
		label: '操作',
		prop: '6'
	},
]

export const goodsDetailDataCenterTableConf = [
	{
		label: '开播时间',
		prop: '1'
	},
	{
		label: '主播',
		prop: '2'
	},
	{
		label: '价格',
		prop: '3'
	},
	{
		label: '销量',
		prop: '4'
	},
	{
		label: '销售额',
		prop: '5'
	},
	{
		label: '操作',
		prop: '6'
	},
]