<template>
	<div class="anchor_data">
		<div class="flex_r f_jc_b">
			<el-radio-group v-model="tabPosition" size="large" style="margin-bottom: 30px;">
				<el-radio-button label="1">淘宝数据</el-radio-button>
				<el-radio-button label="2">抖音数据</el-radio-button>
			</el-radio-group>
			<el-input size="large" class="search">
				<el-button slot="append" type="primary">搜索</el-button>
			</el-input>
		</div>
		<div class="data_type flex_r f_ai_c bg_fff mb20 mt20">
			<span class="cr_999 mr10">主要品类：</span>
			<ul class="flex_r f_ai_c">
				<li class="mr10 cur">全部</li>
				<li class="mr10">服装鞋包</li>
				<li class="mr10">美妆饰品</li>
			</ul>
		</div>
		<div class="data_type flex_r f_ai_c bg_fff mb20 mt20">
			<span class="cr_999 mr10">筛选：</span>
			<ul class="flex_r f_ai_c">
				<li class="mr10">
					销售额
					<el-select>
						<el-option label="1" :value="1"></el-option>
					</el-select>
				</li>
				<li class="mr10">
					销量
					<el-select>
						<el-option label="1" :value="1"></el-option>
					</el-select>
				</li>
			</ul>
		</div>
		<div class="data_type flex_r f_ai_c bg_fff mb20 mt20">
			<span class="cr_999 mr10">筛选：</span>
			<ul class="flex_r f_ai_c">
				<li class="mr10">
					时间
					<el-radio-group v-model="tabPosition">
						<el-radio-button label="1">最新</el-radio-button>
						<el-radio-button label="2">＜3天</el-radio-button>
						<el-radio-button label="2">＜7天</el-radio-button>
						<el-radio-button label="2">＜15天</el-radio-button>
						<el-radio-button label="2">＜30天</el-radio-button>
					</el-radio-group>
				</li>
			</ul>
		</div>
		<e-table :tableCols="goodsDataTableConf" :dataOrigin="dataOrigin">
			<template #1>
				<div class="flex_r">
					<img class="pr10" height="40" width="40" src="//gw.alicdn.com/bao/uploaded/i1/2980888022/O1CN01kMxlkA2987vRQ02IF_!!2980888022-0-picasso.jpg" alt="">
					<div>
						<div class="tl">薇娅viya</div>
						<span>ID： 69226163</span>
					</div>
				</div>
			</template>
			<template #2>
				<div>
					<img class="pr10" height="16" width="16" src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/tb.png?v=1623338071190" alt="">
					<span>淘宝</span>
				</div>
			</template>
			<template #16>
				<router-link tag="a" target="_blank" :to="{path:'/goodsDetailDataCenter'}">查询详情</router-link>
			</template>
		</e-table>
		<e-dialog title="" ref="dialogRef">
		</e-dialog>
	</div>
</template>

<script>
import {goodsDataTableConf} from '@data/anchorData'
export default {
	name: 'GoodsData',
	data() {
		return {
			goodsDataTableConf,
			dataOrigin: {
				data: [
					{}
				]
			},
			tabPosition: 1
		}
	},
	methods: {
		handleClick() {}
	}
}
</script>

<style lang="scss" scoped>
.search {
	width: 400px;
}
.data_type {
	li{ 
		&.cur {
			color: #409EFF;
		}
		&:hover {
			color: #409EFF;
		}
	}
}
</style>

<style lang="scss">
.el-input-group__append {
	background-color: #409EFF !important;
	color: #fff;
}
</style>